
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('externalIncentive.subsidy_application_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(mobile_no)="data">
                                            {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(code)="data">
                                            {{ $n(data.item.code, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(application_type)="data">
                                            {{ getApplicationType(data.item.application_type) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          {{ getStatus(data.item.status) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="details(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-form" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
         <b-modal id="modal-5" size="xl" :title="detailsTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <FarmerDetails :id="editItemId" :key="editItemId"  ref="farmerdetails"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import FarmerDetails from './FarmerDetails'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applocationList } from '../../api/routes'
import RouteBaseList from '@/mixins/route-base-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [RouteBaseList],
  components: {
    Form, FarmerDetails
  },
  data () {
    return {
      search: {},
      editItemId: '',
      item: '',
      rows: [],
      loading: false
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.entry') : this.$t('crop_price_config.market') + ' ' + this.$t('globalTrans.modify')
    },
    detailsTitle () {
       return this.$t('common_config.farmer_info_details')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.application_id'), class: 'text-left' },
          { label: this.$t('dae_subsidy.circular_name'), class: 'text-left' },
          { label: this.$t('common_config.farmer_name'), class: 'text-left' },
          { label: this.$t('common_config.farmer_mobile_name'), class: 'text-left' },
          { label: this.$t('dae_subsidy.instrument_name'), class: 'text-left' },
          { label: this.$t('dae_subsidy.applicationType'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'code' },
          { key: 'circular_name_bn' },
          { key: 'name_bn' },
          { key: 'mobile_no' },
          { key: 'instrument_name_bn' },
          { key: 'application_type' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'code' },
          { key: 'circular_name' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'instrument_name' },
          { key: 'application_type' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    localeCheck: function () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    loadData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, applocationList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.loading = false
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport () {
        this.$refs.farmerdetails.pdfExport()
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
            const circularInfoObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.circularList.find(circular => circular.value === parseInt(item.circular_id))
              const circularData = {}
                  if (typeof circularInfoObj !== 'undefined') {
                      circularData.circular_name = circularInfoObj.text_en
                      circularData.circular_name_bn = circularInfoObj.text_bn
                  } else {
                      circularData.circular_name = ''
                      circularData.circular_name_bn = ''
                  }
              const instrumentInfoObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.find(instrument => instrument.value === parseInt(item.instrument_id))
               const instrumentData = {}
                  if (typeof instrumentInfoObj !== 'undefined') {
                      instrumentData.instrument_name = instrumentInfoObj.text_en
                      instrumentData.instrument_name_bn = instrumentInfoObj.text_bn
                  } else {
                      instrumentData.instrument_name = ''
                      instrumentData.instrument_name_bn = ''
                  }
          return Object.assign({}, item, circularData, instrumentData)
        })
        return listData
    },
    getApplicationType (type) {
      if (type === 1) {
        return this.localeCheck === 'en' ? 'Individual' : 'ব্যক্তিগত'
      } else {
        return this.localeCheck === 'en' ? 'Group' : 'গ্রুপ'
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('externalIncentive.pending')
      } else if (status === 2) {
        return this.$t('externalIncentive.forward')
      } else if (status === 3) {
        return this.$t('externalIncentive.approve')
      } else if (status === 4) {
        return this.$t('globalTrans.reject')
      } else if (status === 5) {
        return this.$t('externalIncentive.distributed')
      }
    }
  }
}
</script>
